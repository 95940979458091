import { ImportOfferType } from "../../../../types/importTypes";

export function getTotalPrice(offer: ImportOfferType) {
  const { priceAfterDiscountHandling } = offer || {};
  const vehiclePrice = priceAfterDiscountHandling || 0;
  const totalFees = getTotalFees(offer);
  return vehiclePrice + totalFees;
}

export function getTotalFees(offer: ImportOfferType) {
  const { processingFee, transportFee, transportAddonPrice, invoiceFee, additionalTransportFee, transportTollFee,
    additionalArrangementsPrice } =
    offer || {};
  let processingFeeVal = processingFee || 0;
  let transportFeeVal = transportFee || 0;
  let transportTollFeeVal = transportTollFee || 0;
  let additionalTransportFeeVal = additionalTransportFee || 0;
  let transportAddonPriceVal = transportAddonPrice || 0;
  let additionalArrangementsPriceVal = additionalArrangementsPrice || 0;
  let invoiceFeeVal = invoiceFee || 0;
  return processingFeeVal + transportFeeVal + transportAddonPriceVal + invoiceFeeVal + additionalTransportFeeVal + transportTollFeeVal + additionalArrangementsPriceVal;
}

export function updateValuesCalculation(name: string, offer: ImportOfferType) {
  const tmp = { ...offer };

  // "when "marked", calculate difference.. When unmarked, no calculation, just the same"
  const percentage = !tmp.isNetPriceVehicle ? 0 : Number(tmp.vatPercentage || 19);
  if (name === "vatPercentage") {
    tmp.salesPriceGross = Math.round((tmp.salesPrice || 0) * ((100 + percentage) / 100));
    tmp.discountGross = Math.round((tmp.discount || 0) * ((100 + percentage) / 100));
    tmp.priceAfterDiscountGross = Math.round((tmp.priceAfterDiscount || 0) * ((100 + percentage) / 100));
  } else if (name === "isNetPriceVehicle") {
    tmp.salesPriceGross = Math.round((tmp.salesPrice || 0) * ((100 + percentage) / 100));
    tmp.discountGross = Math.round((tmp.discount || 0) * ((100 + percentage) / 100));
    tmp.priceAfterDiscountGross = Math.round((tmp.priceAfterDiscount || 0) * ((100 + percentage) / 100));
  } else if (name === "salesPrice") {
    tmp.salesPriceGross = Math.round((tmp.salesPrice || 0) * ((100 + percentage) / 100));
  } else if (name === "salesPriceGross") {
    tmp.salesPrice = Math.round((tmp.salesPriceGross || 0) / ((100 + percentage) / 100));
  } else if (name === "discount") {
    tmp.discountGross = Math.round((tmp.discount || 0) * ((100 + percentage) / 100));
  } else if (name === "discountGross") {
    tmp.discount = Math.round((tmp.discountGross || 0) / ((100 + percentage) / 100));
  } else if (name === "priceAfterDiscount") {
    tmp.priceAfterDiscountGross = Math.round((tmp.priceAfterDiscount || 0) * ((100 + percentage) / 100));
  } else if (name === "priceAfterDiscountGross") {
    tmp.priceAfterDiscount = Math.round((tmp.priceAfterDiscountGross || 0) / ((100 + percentage) / 100));
  }
  // After we have updated different values, we make sure the discount values are updated too
  if (name === "salesPrice" || name === "salesPriceGross" || name === "discount" || name === "discountGross") {
    let salesPrice = tmp.salesPrice || 0;
    let discount = tmp.discount || 0;
    tmp.priceAfterDiscount = salesPrice - discount;
    salesPrice = tmp.salesPriceGross || 0;
    discount = tmp.discountGross || 0;
    tmp.priceAfterDiscountGross = salesPrice - discount;
  } else if (name === "priceAfterDiscount" || name === "priceAfterDiscountGross") {
    let salesPrice = tmp.salesPrice || 0;
    let afterDiscount = tmp.priceAfterDiscount || 0;
    tmp.discount = salesPrice - afterDiscount;
    salesPrice = tmp.salesPriceGross || 0;
    afterDiscount = tmp.priceAfterDiscountGross || 0;
    tmp.discountGross = salesPrice - afterDiscount;
  }

  return tmp;
}
