import React, { FC } from "react";
import Details from "../../../../components/detailsList";
import ImportCaseStore from "../../../../contexts/importCaseStore";
import _t from "../../../../lang/translate";
import format from "../../../../utilities/formatNumbers";
import { Tooltip } from "antd";
import Icon from "../../../../assets/icons/icon";

const ImportOfferInfo: FC = () => {
  const { importCase } = ImportCaseStore.useContainer();
  const { offer } = importCase.vehicleOffer || {};
  const {
    transportAddonPrice,
    transportFee,
    transportTollFee,
    additionalTransportFee,
    discountFee,
    additionalArrangementsPrice,
    additionalArrangementsInfo,
    invoiceFee,
    totalFees,
    totalPrice,
    salesPrice,
    priceAfterDiscountHandling,
    priceAfterDiscountHandlingGross,
    discount,
    isNetPriceVehicle,
    processingFee,
  } = offer || {};

  const formatPrice = (price?: number | null) => (price ? format.price(price, 0, "EUR") : "-");
  const visualDiscount = (discount || 0) * -1;

  return (
    <Details borderLess className="m-0">
      <Details.Item label={`${_t("offer_salesPrice")} (${_t("offer_net_price")})`} value={formatPrice(salesPrice)} />
      <Details.Item label={`${_t("offer_discount")} (${_t("offer_net_price")})`} value={formatPrice(visualDiscount)} />
      <Details.Item
        label={
          <>
            {_t("offer_discountFee")}
            &nbsp;
            <Tooltip title={_t("offer_discountFee_help")}>
              <Icon name="information-circle-outline" fill={"black"} />
            </Tooltip>
          </>
        }
        value={discountFee ? formatPrice(discountFee) : "-"}
      />
      <Details.Item
        bold
        label={`${_t("offer_priceAfterDiscountHandling")} - ${_t(isNetPriceVehicle ? "withoutMoms_short" : "taxDead")}`}
        value={formatPrice(isNetPriceVehicle ? priceAfterDiscountHandling : priceAfterDiscountHandlingGross)}
      />
      <Details.Divider />

      <Details.Item
        label={<>
          {`${_t("offer_processingFee")} - ${_t("withoutMoms_short")}`}
          &nbsp;
          <Tooltip title={_t("offer_processingFee_help")} overlayInnerStyle={{ whiteSpace: "pre-wrap" }}>
            <Icon name="information-circle-outline" fill={"black"} />
          </Tooltip>
        </>
        }
        value={formatPrice(processingFee)}
      />

      <Details.Item
        label={
          <>
            {_t("offer_transportFee")}
            &nbsp;
            <Tooltip title={_t("offer_transportFee_help")}>
              <Icon name="information-circle-outline" fill={"black"} />
            </Tooltip>
          </>
        }
        value={transportFee ? formatPrice(transportFee) : "-"}
      />

      {additionalTransportFee ? (
        <Details.Item
          label={
            <>
              {_t("offer_additionalTransportFee")}
            &nbsp;
              <Tooltip title={_t("offer_additionalTransportFeeInfo")}>
                <Icon name="information-circle-outline" fill={"black"} />
              </Tooltip>
            </>
          }
          value={formatPrice(additionalTransportFee)}
        />
      ) : null}

      <Details.Item
        label={
          <>
            {_t("offer_transportAddonPrice")}
            &nbsp;
            <Tooltip title={_t("offer_transportAddonPrice_help")}>
              <Icon name="information-circle-outline" fill={"black"} />
            </Tooltip>
          </>
        }
        value={transportAddonPrice ? formatPrice(transportAddonPrice) : "-"}
      />

      <Details.Item
        label={_t("offer_transportTollFee")}
        value={transportTollFee ? formatPrice(transportTollFee) : "-"}
      />

      <Details.Item
        label={
          <>
            {_t("invoice_fee")}
            &nbsp;
            <Tooltip title={_t("invoice_fee_help")}>
              <Icon name="information-circle-outline" fill={"black"} />
            </Tooltip>
          </>
        }
        value={invoiceFee ? formatPrice(invoiceFee) : "-"}
      />

      {additionalArrangementsInfo ? (
        <Details.Item
          label={additionalArrangementsInfo}
          value={additionalArrangementsPrice ? formatPrice(additionalArrangementsPrice) : "-"}
        />
      ) : null}

      <Details.Item bold label={_t("offer_totalFees")} value={formatPrice(totalFees)} />

      <Details.Divider />
      <Details.Item bold label={_t("total_price")} value={formatPrice(totalPrice)} />
    </Details>
  );
};

export default ImportOfferInfo;
