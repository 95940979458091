import { Button, Form, message, Modal, Space } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { FC, useState } from "react";
import EnumSelect from "../../../components/form/enumSelect";
import SwitchInput from "../../../components/form/switchInput";
import DealerAdminStore from "../../../contexts/dealerAdminStore";
import DealerStore from "../../../contexts/dealerStore";
import _t from "../../../lang/translate";
import { updateDealerLevel } from "../../../services/dealerService";
import { DealerAdminProductType, DealerLevelType, initDealerLevel } from "../../../types/dealerTypes";
import { isAxiosError } from "../../../utilities/typeGuard";
import DateSelect from "../../../components/form/dateSelect";

interface DealerUpdateLevelButtonProps {
  product: DealerAdminProductType;
}

const DealerUpdateLevelButton: FC<DealerUpdateLevelButtonProps> = ({ product }) => {
  const { dealer } = DealerStore.useContainer();
  const { admin, setAdmin } = DealerAdminStore.useContainer();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { level, context } = product;
  const initialValues = level || initDealerLevel;

  const openModal = () => setIsVisible(true);
  const closeModal = () => setIsVisible(false);

  const handleSubmit = async (values: DealerLevelType) => {
    try {
      setLoading(true);
      const nextLevel = { ...level, ...values, forced: !values.autoStatus };
      const data = { [product.context]: nextLevel };
      await updateDealerLevel(dealer.id, data);
      const nextProducts = admin.products.map((prod) =>
        prod.context === context ? { ...prod, level: nextLevel } : prod
      );
      setAdmin({ ...admin, products: nextProducts });
      closeModal();
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button type="link" className="p-0" onClick={openModal}>
        {_t("edit")}
      </Button>
      <Modal width={500} visible={isVisible} onCancel={closeModal} title={null} footer={null}>
        <Form<DealerLevelType>
          onFinish={handleSubmit}
          initialValues={{ ...initialValues, autoStatus: !initialValues.forced }}
          layout="vertical"
        >
          <h2 className="modal-title">{_t("edit", "level")}</h2>
          <Form.Item name="isPriority" valuePropName="checked">
            <SwitchInput size="small" disabled={loading} label={_t("priority")} />
          </Form.Item>
          <Form.Item name="autoStatus" valuePropName="checked">
            <SwitchInput size="small" disabled={loading} label={_t("auto", "status")} />
          </Form.Item>
          <Form.Item shouldUpdate={(prev, cur) => prev.autoStatus !== cur.autoStatus} noStyle>
            {({ getFieldValue }) => (
              <Form.Item name="levelId" label={_t("level")}>
                <EnumSelect disabled={!!getFieldValue("autoStatus") || loading} nameKey={["level"]} />
              </Form.Item>
            )}
          </Form.Item>

          <Form.Item name="note" label={_t("note")}>
            <TextArea autoSize={{ minRows: 1, maxRows: 3 }} />
          </Form.Item>
          <Form.Item label={_t("note", "expires")} name="noteExpiresAt">
            <DateSelect />
          </Form.Item>

          <Form.Item name="note2" label={`${_t("note")} 2`}>
            <TextArea autoSize={{ minRows: 1, maxRows: 3 }} />
          </Form.Item>
          <Form.Item label={`${_t("note", "expires")} 2`} name="note2ExpiresAt">
            <DateSelect />
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({ submit }) => (
              <div className="text-right">
                <Space>
                  <Button type="ghost" onClick={closeModal} disabled={loading}>
                    {_t("cancel")}
                  </Button>
                  <Button onClick={submit} loading={loading} type="primary">
                    {_t("save")}
                  </Button>
                </Space>
              </div>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default DealerUpdateLevelButton;
