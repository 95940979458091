import { UploadFile } from "antd/lib/upload/interface";
import { latestVersion, TaxCalcVersionType, } from "../screens/forms/tax/calc/taxCalcService";
import { TradePriceCalcOutputType } from "../screens/forms/tax/calc/tradePriceCalculatorTypes";
import {
  Client,
  EnumItemType,
  Equipment,
  IdType,
  InitialClient,
  LinkType,
  LogObj,
  Person,
  RelatedCasesType,
  Vehicle,
} from "./appTypes";
import { MotorAgencyDataType } from "./leasingTypes";
import { TaxAdVehiclesType } from "./taxAdvancedCalcTypes";
import { StoreTaxEstimationRobot } from "./taxEstimationTypes";
import { CardPayments, PaymentDetails } from "./paymentCardType";

export interface TaxListItem {
  id: IdType;
  status: EnumItemType;
  expectedUpdateTime: Date;
  car: string;
  horsePower: string;
  createdAt: string;
  recalculationRequestedAt:	string | null;
  importantNote: string | null;
  uploaded: boolean;
  paid: boolean;
  followUp: boolean;
  registrationFee: number;
  vin: number;
  waitTime: number;
  invoiceNumber: number;
  mileage: number;
  isPriority: boolean;
  dealerIsPriority: boolean;
  clientName: string;
  dealerName: string;
  newDealer?: boolean;
  caseHandler: Person;
  isFirstCase: boolean;
  isInProgress: boolean;
}

const taxDataSources = ["vin", "link", "manual"] as const;

export type TaxDataSourceType = typeof taxDataSources[number];

export function isTaxDataSource(key: string): key is TaxDataSourceType {
  return taxDataSources.includes(key as TaxDataSourceType);
}

// api/v1/TaxCase
export interface TaxCaseCreation {
  id: IdType;
  vehicle: Vehicle | null;
  note: string | null;
  dataSourceId?: IdType;
  evaluationTypeId: IdType;
  vehicleDataSource: Vehicle | null;
  calculationTypeId: IdType;
  files: UploadFile[] | null;
  client: Client | InitialClient | null;
  flags: TaxFlagsType;
  paymentMethodId: IdType;
  reason?: string | null;
}

export interface DynamicFee {
  min: number;
  max: number;
  when0RegFeeRule: number | null;
  ratio: number;
}

export interface TaxCase extends TaxCaseCreation {
  version: TaxCalcVersionType;
  legalFiles?: UploadFile[] | null;
  status: EnumItemType | null;
  billingDestinationId?: IdType;
  importantNote: string | null;
  relatedCases?: RelatedCasesType;
  relatedResellerTaxCase: LinkType | null;
  client: Client | null;
  flags: TaxFlagsType;
  log: LogsTaxCase | null;
  invoiceStartedAt?: string | null;
  receipt: Receipt | null;
  links: LinksTax | null;
  calculation: OfferTax | null;
  internalCalculation: OfferTax | null;
  dynamicFee: DynamicFee;
  defaultFee: number | null;
  documentationSettings: TaxDocSettingsType | null;
  documentationNote: string | null;
  documentationFiles: DocumentationFilesType | null;
  motorAgency: MotorAgencyDataType | null;
  latestTaxRobotResult: StoreTaxEstimationRobot | null;
  taxCaseValueConfig?: Record<TaxCaseValueConfigItemIds, TaxCaseValueConfigItem>;
  cardPayments?: null | CardPayments;
  paymentDetails: null | PaymentDetails;
  isInProgress: boolean;
  timeToSendOfferInMs: number | null;
  expectedRegistrationDate: string | null;
  caseSource?: string;
  caseSourceId?: number | string;
}

type TaxCaseValueConfigItemIds =
  | "numberPlateFee"
  | "handlingFee"
  | "paymentTermCode"
  | "requireFilesBeforeAcceptance"
  | "purchasableDocumentations"
  | "dynamicFee"
  | "dynamicFeeMaxZeroTaxVehicleFeeAmount"
  | "kickbackAmount"
  | "accountingKickbackAmountSupplier"
  | "accountingKickbackAmountEndUser"
  | "clipCost"
  | "yellowToWhiteClipCost"
  | "guaranteeValidityMonths";

export interface TaxCaseValueConfigItem {
  id: TaxCaseValueConfigItemIds,
  title: string,
  type: string,
  value: number | string | boolean,
  forced: boolean,
  unit?: string;
  options?: {
    id: null | string | number,
    title: null | string | number
  }[]
}

export interface DocumentationFilesType {
  ads?: UploadFile | null;
  vehicleList?: UploadFile | null;
  newPrice?: UploadFile | null;
  equipments?: UploadFile | null;
}

export interface TaxDocSettingsType {
  documentationExpirationDate?: string | null;
  productPrice: number | null;
  productPriceWithVat: number | null;
}

export interface TaxFlagsType {
  isPriority: boolean;
  partnerCase: boolean;
  forcedStatus?: boolean;
  calculateDynamicFee?: boolean;
  accepted?: boolean;
  uploaded?: boolean;
  paid?: boolean;
  published?: boolean;
  sent?: boolean;
  expired?: boolean;
  allowRecalculateRequest?: boolean;

  receipted?: boolean;
  documentationEnabled?: boolean;
  registered: boolean;
  documentationPublished: boolean;
  documentationSent: boolean;
  documentationBought: boolean;
  generatingDocumentation: boolean;
  followUp: boolean;
  isFirstCase: boolean;
}

export interface CalculationOutputType {
  headers: {
    key: string;
    dataIndex: string;
    title: string;
  }[];
  data: any[];
}

export interface VehicleDataSource {
  typeId: IdType;
  link?: string;
  vin?: string;
  vehicle?: Vehicle;
  isInspected?: boolean;
  mileage?: number;
  useCaseId?: IdType;
}

export interface LogsTaxCase {
  creation: LogObj | null;
  uploaded: LogObj | null;
  offerPublished: LogObj | null;
  offerSent: LogObj | null;
  accepted: LogObj | null;
  preAssessed: LogObj | null;
  rejected: LogObj | null;
  paid: LogObj | null;
  receipt: LogObj | null;
  documentationPublished?: LogObj | null;
  documentationSent: LogObj | null;
  documentationBought: LogObj | null;
  registered: LogObj | null;
}

export interface Receipt {
  title: string;
  content: string;
}

// api/taxCase/:id/optimization/updateVehicle
// api/taxCase/:id/preAssesment/updateVehicle
/* export interface VehicleTax {
  vehicle: Vehicle;
  equipments: Equipment[];
  newPrice: number;
  vehiclePublished: boolean;
} */

/* export interface VehicleAdCalculation {
  tradePrice: number;
  first_reg_date: Date;
  mileage: number;
  adjustment: number;
  note: string | null;
  equipments: Equipment[];
} */

/* export interface TradePriceCalculation {
  newPrice: number | null;
  equipmentPrice: number | null;
  equipmentPriceIsExcluded: boolean | null;
  tradePrice: number | null;
  averagePrice: number;
  vehicleAdCount: number;
  averageMileage: number;
  adjustment: number;
  note: string | null;
  output: CalculationOutputType | null;
  // vehicleAds: VehicleAdCalculation[];
}
 */
export interface RegFeeCalculationType {
  newPrice: number | null;
  tradePrice: number | null;
  emissionUnit: IdType | null;
  vehicleType: IdType | null;
  isNew: boolean | null;
  electricConsumption: number | null;
  electricRange: number | null;
  taxableValueCustom: number | null;
  emissionValue: number | null;
  output?: any;
  // vehicleAds: VehicleAdCalculation[];
}

export interface OfferTax {
  tradePrice: TradePriceCalcTaxType | null;
  newPrice: NewPriceCalcTaxDocType | null;
  registrationFee: RegFeeCalcTaxDocType | null;

  optimization?: boolean;
  taxablePrice?: number | null;
  total: number | null;
  licensePlateFee: number | null;
  fee: TaxOfferFeeType | null;
  estimatedCollateral?: TaxEstimatedCollateralType | null;
  collateral?: number | null;
  createdAt: string | null;
  isAcceptable: boolean;
  // Temporary field to support 2025 taxcalculations.
  projected?: {
    calculation?: any;
    estimatedRegistrationFee?: number;
  }
}

export interface TaxOfferFeeType {
  autoCalculate: boolean;
  fee: number | null;
}

export interface TaxEstimatedCollateralType {
  autoCalculate: boolean;
  estimatedCollateral: number | null;
}

export interface NewPriceCalcTaxDocType {
  newPriceWithoutEquipments: number | null;
  technicalNewPrice: number | null;
  newPriceSource: string | null;
  documentationDate: string | null;
  newPrice: number | null;
  useTechnicalNewPrice: boolean;
  equipmentsPrice: number | null;
  equipmentCalculator: EquipmentCalculatorType | null;
}

export interface EquipmentCalculatorType {
  equipments: Equipment[] | null;
  euroFactor: number | null;
}

export interface TradePriceCalcTaxType {
  adVehicles?: TaxAdVehiclesType;
  tradePriceOutput?: TradePriceCalcOutputType;
  estimatedTradePrice: number | null;
  useEstimatedTradePrice: boolean;
  optimizedRegulation: number | null;
  optimizedRegulationAmount: number | null;
  otherRegulation: number | null;
  otherRegulationAmount: number | null;
  tradePrice: number | null;
}

export interface RegFeeCalcTaxDocType {
  autoCalculate: boolean;
  registrationFee: number | null;
  registrationFeeOutput: RegistrationFeeResults | null;
}

export interface PrevOfferTax extends OfferTax {
  id: IdType;
  createdAt: string | null;
  person: Person | null;
}

export type SendOfferFormType = {
  message?: string | null;
  isAcceptable?: boolean;
};

export type AcceptOfferFormType = {
  billingDestinationId?: number;
  status: boolean;
};

export interface LinksTax {
  justificationLink: string | null;
  invoiceLink: string | null;
  offerPdfLink: string | null;
  equipmentReportLink: string | null;
  terms: string | null;
  documentation: string | null;
  dealerView: string | null;
}

export interface RegistrationFeeResults {
  energyType: {
    id: number | string;
    title: string;
    color: string;
  };
  emission: number;
  batteryCapacity: number;
  valueLoss: number;
  batteryDeduction: number;
  scales: number[];
  grossTax: number;
  emissions: number[];
  emissionAddon: number;
  registrationFee: number;
  bottomDeduction: number;
  taxDeduction: number;
  lowDeduction: number;
  taxableValue: number;
  tax: number;
}

export interface UpdateDocumentationNoteType {
  documentationNote: string;
}

export interface PurchaseType {
  agreedTerms: boolean;
  cardId?: string | null;
  useFreePurchase?: boolean;
}

export interface MakePurchaseType {
  reason: string | null;
}

export interface RejectCaseType {
  reason: string | null;
}

/* export interface Assesment {
  offer: OfferTax | null;
  vehicle: VehicleTax | null;
  tradePriceCalculation: TradePriceCalculation | null;
} */

export type TaxCreationRequestType = { email: string };

export type TaxRecalculateRequestType = { mileage: number | null; reason: string | null; };
export type CloneTaxCaseType = { evaluationTypeId: IdType };

export const initRegFee: RegFeeCalculationType = {
  newPrice: null,
  tradePrice: null,
  emissionUnit: 1,
  vehicleType: 1,
  isNew: false,
  electricConsumption: null,
  electricRange: null,
  taxableValueCustom: null,
  emissionValue: null,
};

export const initDynamicFee: DynamicFee = {
  min: 800,
  max: 2000,
  when0RegFeeRule: null,
  ratio: 0.01,
};

export const initTaxCase: TaxCase = {
  id: 0,
  version: latestVersion,
  paymentMethodId: 1,
  evaluationTypeId: 1,
  vehicleDataSource: null,
  importantNote: null,
  relatedResellerTaxCase: null,
  note: null,
  calculationTypeId: 1,
  files: [],
  documentationFiles: null,
  documentationNote: null,
  motorAgency: null,
  documentationSettings: null,
  defaultFee: null,
  client: null,
  status: null,
  flags: {
    forcedStatus: false,
    partnerCase: false,
    calculateDynamicFee: false,
    accepted: false,
    uploaded: false,
    paid: false,
    published: false,
    sent: false,
    receipted: false,
    isPriority: false,
    documentationBought: false,
    documentationPublished: false,
    documentationSent: false,
    generatingDocumentation: false,
    registered: false,
    expired: false,
    allowRecalculateRequest: false,
    followUp: false,
    isFirstCase: false,
  },
  dynamicFee: initDynamicFee,
  log: null,
  receipt: null,
  links: null,
  calculation: null,
  internalCalculation: null,
  vehicle: {
    typeId: 1,
  },
  legalFiles: null,
  latestTaxRobotResult: null,
  paymentDetails: null,
  isInProgress: false,
  timeToSendOfferInMs: null,
  expectedRegistrationDate: null
};
