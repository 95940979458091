import React, { FC } from "react";
import DatePicker from "antd/lib/date-picker";
import { PickerDateProps } from "antd/lib/date-picker/generatePicker";
import moment, { Moment } from "moment";
import consts from "../../utilities/consts";

export interface DateSelectProps extends Omit<PickerDateProps<Moment>, "value" | "onChange"> {
  value?: string;
  dark?: boolean;
  //format?: any[];
  onChange?: (value: string) => void;
}

const DateSelect: FC<DateSelectProps> = ({ value, onChange, dark, dropdownClassName, format, ...rest }) => {
  const handleChange = (date: Moment | null) => {
    if (!onChange) return;

    if (date !== null) onChange(date.toISOString(true));
    else onChange(null!) // Type Error --> null! negates the type error
  };

  return (
    <DatePicker
      defaultPickerValue={moment()}
      dropdownClassName={`${dark ? "dark-picker " : ""}${dropdownClassName}`}
      value={value ? moment(value) : null}
      onChange={handleChange}
      format={format || consts.dateFormats}
      {...rest}
    />
  );
};

export default DateSelect;
