import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "antd";
import LinkBox from "../../../../../components/form/linkBox";
import _t from "../../../../../lang/translate";
import { saveTaxVehicle } from "../../../../../services/taxService";
import { BaseVehicle, Vehicle } from "../../../../../types/appTypes";
import consts from "../../../../../utilities/consts";
import TaxVehicleForm from "../taxVehicleForm";
import { renderErrorMessage } from "../../../../../components/messages/errorMessage";
import { TaxCase } from "../../../../../types/taxTypes";
import Icon from "../../../../../assets/icons/icon";
import { isValidLink } from "../../../../../utilities/readLink";
import { getRemoteData } from "../../../../../services/vehicleService";
import { isValidVin } from "../../../../../utilities/stringExtraFunctions";
import VehicleDataSourceList from "./taxVehicleDataSourceList";
import { isDMREmssionUnitEqual } from "../../../../../utilities/mapDataFromDMR";
import PopoverList from "../../../../../components/popups/popoverList"
import appConfig from "../../../../../appConfig";

type TaxVehicleEditModalProps = {
  isEditing: boolean;
  stopEditing: () => void
  startEditing: () => void
  taxCase: TaxCase;
  setTaxCase: (newCase: TaxCase) => void;
  DATVehicleData: BaseVehicle | null | undefined;
  DMRVehicleData: BaseVehicle | null | undefined;
  setDMRVehicleData: React.Dispatch<React.SetStateAction<BaseVehicle | null | undefined>>;
  setDATVehicleData: React.Dispatch<React.SetStateAction<BaseVehicle | null | undefined>>;
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
}

const TaxVehicleEditModal = ({
  isEditing, stopEditing, startEditing, taxCase, setTaxCase, DATVehicleData, DMRVehicleData, setDMRVehicleData, setDATVehicleData, setIsSaving
}: TaxVehicleEditModalProps) => {
  const [isLoading, setIsLoading] = useState({
    dat: false,
    dmr: false,
  });
  const [formVinValue, setFormVinValue] = useState(taxCase.vehicle?.vin || "");
  const isWidgetCase = taxCase.caseSourceId ===  appConfig("widgetCaseSourceId");
  const dmrWarningFields = getDmrWarningMessages(taxCase.vehicle);
  const vehicleLink = taxCase.vehicle?.link;

  const vehicleInitialValues = taxCase.vehicle || {};
  vehicleInitialValues.addModelAlias = vehicleInitialValues.meta?.rawModel || null;
  vehicleInitialValues.addVariantAlias = vehicleInitialValues.meta?.rawVariant || null;

  const [form] = Form.useForm<Vehicle>();
  const submit = () => form.submit();

  const handleSave = async (values: Vehicle) => {
    try {
      stopEditing();
      setIsSaving(true)
      const { data } = await saveTaxVehicle(taxCase.id, values);
      setTaxCase(data);
    } catch (error) {
      renderErrorMessage(error);
      startEditing()
    } finally {
      setIsSaving(false)
    }
  };

  function onCancel() {
    stopEditing();
    form.resetFields()
  }

  const handleFormChange = (changedValues: Partial<Vehicle>, values: Vehicle) => {
    if (changedValues.vin) {
      setFormVinValue(changedValues.vin);
    }
  };

  const standardVehicleCopy = () => {

    const dealer = taxCase.vehicleDataSource;
    const dmr = DMRVehicleData;
    const dat = DATVehicleData;
    const newFormValues: Partial<Vehicle> = {

      typeId: dealer?.typeId ?? dmr?.typeId,
      isNew: dealer?.isNew ?? dmr?.isNew,
      brandId: dat?.brandId,
      model: dat?.model,
      equipmentVariant: dat?.equipmentVariant,
      modelDesignation: dat?.modelDesignation,
      modelPeriod: dat?.modelPeriod,
      firstRegDate: dmr?.firstRegDate || dealer?.firstRegDate,
      vin: dealer?.vin || dmr?.vin,
      mileage: dealer?.mileage || dmr?.mileage,

      engineSize: String(dat?.engineSize ?? ''),
      horsePower: String(dat?.horsePower ?? ''),
      gearboxId: dat?.gearboxId,
      bodyId: dat?.bodyId,
      qualityId: dat?.qualityId,
      useCaseId: dat?.useCaseId,

      fuelId: dmr?.fuelId ?? dat?.fuelId,
      emissionAmount: dmr?.emissionAmount ?? dat?.emissionAmount,
      emissionUnitId: dmr?.emissionUnitId ?? dat?.emissionUnitId,
      batteryCapacity: dmr?.batteryCapacity ?? dat?.batteryCapacity,
      electricConsumption: dmr?.electricConsumption ?? dat?.electricConsumption,
      electricRange: dmr?.electricRange ?? dat?.electricRange,
    }
    if (dealer?.link) {
      newFormValues.link = dealer?.link;
    }
    if (dealer?.isHighWeight) {
      newFormValues.isHighWeight = dealer?.isHighWeight;
    }
    form.setFieldsValue(newFormValues)
  }


  const getRemoteVehicleData = async (
    sourceType: Parameters<typeof getRemoteData>[1]['items'] // (dmr|dat)[]
  ) => {
    try {
      if (typeof formVinValue === 'string' && isValidVin(formVinValue)) {

        sourceType.forEach(key => setIsLoading((prev) => ({ ...prev, [key]: true })))
        let warningMessage: string[] = [];
        const { data: { data: remoteVehicleData } } = await getRemoteData(formVinValue, { items: sourceType, forceFresh: true })
        sourceType.forEach(key => {
          if (remoteVehicleData[key] == null) {
            warningMessage.push((_t("msg.no_data_found_for") + ": " + key.toUpperCase()));
          }
          switch (key) {
            case "dmr": setDMRVehicleData(remoteVehicleData[key]?.mapped); break;
            case "dat": setDATVehicleData(remoteVehicleData[key]?.mapped); break;
            default: throw new Error("sourceType needs to be an array of keys 'dat' and/or 'dmr'");
          }
        })

        if (warningMessage.length) {
          throw new Error(warningMessage.toString())
        }
      }
    } catch (error) {
      renderErrorMessage(error)
    } finally {
      sourceType.forEach(key => setIsLoading((prev) => ({ ...prev, [key]: false })))
    }
  }

  return (
    <Modal
      visible={isEditing}
      footer={null}
      onCancel={onCancel}
      width={1440}
      title={_t("edit", "vehicle")}
    >
      <Row gutter={16}>
        <Col span={8}>
          <Button
            icon={<Icon name="refresh-outline" />}
            loading={isLoading.dat && isLoading.dmr}
            disabled={!isValidVin(formVinValue)}
            onClick={() => getRemoteVehicleData(['dat', 'dmr'])}
            className="mb-3 ml-auto d-block"
          >
            {_t("vehicle")}
          </Button>
          <Form
            form={form}
            {...consts.formItemProps}
            className="m-0 form-items-mb-05"
            onValuesChange={handleFormChange}
            initialValues={vehicleInitialValues}
            requiredMark={false}
            onFinish={handleSave}
          >
            {vehicleLink && (
              <Form.Item label={_t("link")} className="form-items-min-w-0" shouldUpdate labelCol={{ span: 3 }} labelAlign="left" style={{ flexWrap: "nowrap" }} wrapperCol={{ span: 21 }}>
                {({ setFieldsValue }) => {
                  return (
                    <LinkBox
                      link={vehicleLink}
                      onFetch={(data) => {
                        const vehicle = { ...data.vehicle };
                        vehicle.typeId = taxCase.vehicle?.typeId;
                        setFieldsValue(vehicle);
                      }}
                    />
                  );
                }}
              </Form.Item>
            )}
            <TaxVehicleForm showListingLocation={isWidgetCase} hideBottomDivider />
          </Form>
          <Row align="bottom" gutter={8}>
            <Col flex={1}>
              <Button onClick={standardVehicleCopy}
                type="primary"
                className="w-100 mt-1"
                icon={<Icon name="copy-outline" />}
              >
                {_t("standard_copying")}
              </Button>
            </Col>
            <Col flex={0} style={{ display: 'flex', alignItems: "center", height: 31 }}>
              <PopoverList list={standardCopyPopoverListText}>
                <Icon className="muted no-bg" size="mega" name="information-circle" />
              </PopoverList>
            </Col>
          </Row>
        </Col>

        <Col span={6}>
          <VehicleDataSourceList
            sourceType={"dat"}
            localVehicle={DATVehicleData}
            form={form}
            getRemoteVehicleData={() => getRemoteVehicleData(['dat'])}
            dealerDataHasLink={isValidLink(taxCase.vehicleDataSource?.link)}
            formVinValue={formVinValue}
            loading={isLoading.dat}
            warningFields={new Map()}
          />
        </Col>
        <Col span={5}>
          <VehicleDataSourceList
            sourceType={"dmr"}
            localVehicle={DMRVehicleData}
            getRemoteVehicleData={() => getRemoteVehicleData(['dmr'])}
            form={form}
            dealerDataHasLink={isValidLink(taxCase.vehicleDataSource?.link)}
            formVinValue={formVinValue}
            loading={isLoading.dmr}
            warningFields={dmrWarningFields}
          />
        </Col>
        <Col span={5}>
          <VehicleDataSourceList
            sourceType={"dealer"}
            localVehicle={taxCase.vehicleDataSource}
            form={form}
            dealerDataHasLink={isValidLink(taxCase.vehicleDataSource?.link)}
            loading={false}
            formVinValue={formVinValue}
            warningFields={new Map()}
          />
        </Col>
      </Row>
      <Row justify="space-between" className="mt-1">
        <Col>
          <Button onClick={onCancel}>{_t("cancel")}</Button>
        </Col>
        <Col>
          <Button type="primary" onClick={submit}>
            {_t("save")}
          </Button>
        </Col>
      </Row>
    </Modal>
  )
}
export default TaxVehicleEditModal;

type WarningFields = React.ComponentProps<typeof VehicleDataSourceList>['warningFields']
const getDmrWarningMessages = (vehicle: TaxCase['vehicle']): WarningFields => {
  const warningMessages: WarningFields = new Map();
  if (!vehicle) { return warningMessages; }

  if (isDMREmssionUnitEqual(vehicle).isDerived) {
    warningMessages.set('emissionUnit', [_t("dmr_data_unclear_on_emission_unit")])
  }

  return warningMessages;
}


const standardCopyPopoverListText = [
  `${_t("link")} --> Forhandler`,
  `${_t("vehicle_type")} --> 1. Forhandler, 2. DMR`,
  `${_t("new", "vehicle")} --> 1. Forhandler, 2. DMR`,
  `${_t("brand")} --> DAT`,
  `${_t("model")} --> DAT`,
  `${_t("equipment_variant")} --> DAT`,
  `${_t("model_designation")} --> DAT`,
  `${_t("model_period")} --> DAT`,
  `${_t("firstRegDate")} --> 1. DMR, 2. Forhandler`,
  `${_t("vin")} --> 1. Forhandler, 2. DMR`,
  `${_t("mileage")} --> 1. Forhandler, 2. DMR`,

  `${_t("engine_size")} --> DAT`,
  `${_t("horse_power")} --> DAT`,
  `${_t("gearbox")} --> DAT`,
  `${_t("trim")} --> DAT`,
  `${_t("car_condition")} --> DAT`,
  `${_t("special_use")} --> DAT`,
  `${_t("over_3000_kg")} --> Forhandler`,

  `${_t("fuel_type")} --> DMR`,
  `${_t("co2_emission")} --> DMR`,
  `${_t("co2_measurement")} --> DMR`,
  `${_t("battery_capacity")} --> DMR`,
  `${_t("battery_usage")} --> DMR`,
  `${_t("battery_range")} --> DMR`,
]
