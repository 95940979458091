import React, { FC } from "react";
import { Button, Divider, Popover, Space, Tag, Typography } from "antd";
import { Link } from "react-router-dom";
import { Client } from "../types/appTypes";
import ClientInfo from "../screens/forms/tax/admin/clientInfo";
import Icon from "../assets/icons/icon";
import { getPath } from "../routes/appRoutes";
import _t from "../lang/translate";

const { Title } = Typography;

interface CaseHeadClientProps {
  client: Client | null;
  onClientChange?: () => void;
  hideEditButton?: boolean;
  isWidgetCase?: boolean;
}

const CaseHeadClient: FC<CaseHeadClientProps> = ({ client, onClientChange, hideEditButton, isWidgetCase }) => {
  const dealerIsPriority = client?.dealerIsPriority || false;
  return client ? (
    <>
      <Title level={3} ellipsis className="mb-0">
        <Space split={<Divider type="vertical" className="ml-05 mr-05" />}>
          <Link className="text-dark"
            to={getPath("dealer", client?.dealerId)}
            title={dealerIsPriority ? _t("priority_dealer") : undefined}
          >
            {dealerIsPriority && (
              <Icon
                color="#0299FB"
                name="star-outline"
                size="large"
                style={{ verticalAlign: '1px', marginRight: 8 }}
              />
            )}
            {`${client?.dealerName ? client?.dealerName + ", " : ""}${client?.name}`}
          </Link>
          {!hideEditButton && (
            <Typography.Link onClick={onClientChange} className="text-dark">
              <Icon name="settings-outline" size="medium" />
            </Typography.Link>
          )}
        </Space>
      </Title>
      <Space wrap style={{rowGap: 0}} split={<Divider type="vertical" className="ml-05 mr-05" />}>
        <Popover content={<ClientInfo client={client} />} placement="bottomLeft">
          <Button className="p-0" type="link">
            {_t("info")}
          </Button>
        </Popover>
        {client?.email && (
          <Button className="p-0" type="link">
            <a href={`mailto:${client.email}`}>{client.email}</a>
          </Button>
        )}
        {client?.phone && (
          <Button className="p-0" type="link">
            <a href={`tel:${client.phone}`}>
              {_t("phone_short")}
              {client.phone}
            </a>
          </Button>
        )}
        {client?.subscriptionPackage && (
          <Tag className="ant-tag-exception text-medium" color="green">{client?.subscriptionPackage}</Tag>
        )}
        {isWidgetCase && (
          <Tag className="ant-tag-exception text-medium" color="magenta">TAX Widget</Tag>
        )}
      </Space>
    </>
  ) : null;
};

export default CaseHeadClient;
