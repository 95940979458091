import React, {FC} from "react";
import {Col, Row} from "antd";
import ImportCaseStore from "../../../../contexts/importCaseStore";
import format from "../../../../utilities/formatNumbers";
import _t from "../../../../lang/translate";
import DividerLine from "../../../../components/lineSplit";

const ImportOfferInfoTable: FC = () => {
  const {importCase} = ImportCaseStore.useContainer();
  const {offer} = importCase.vehicleOffer || {};
  const {
    totalPrice,
    salesPrice,
    salesPriceGross,
    priceAfterDiscount,
    priceAfterDiscountGross,
    priceAfterDiscountHandling,
    priceAfterDiscountHandlingGross,
    discount,
    discountGross,
    processingFee,
    transportFee,
    additionalTransportFee,
    additionalArrangementsInfo,
    additionalArrangementsPrice,
    transportTollFee,
    transportAddonPrice,
    invoiceFee,
    discountFee,
    totalFees,
  } = offer || {};

  const formatPrice = (price?: number | null) => (price ? format.price(price, 0, "EUR") : "-");

  const labelColProps = {
    className: "text-secondary",
    span: 10,
  };

  const valueColProps = {
    className: "text-right",
    span: 7,
  };

  return (
    <>
      <Row className="mb-05">
        <Col {...labelColProps}></Col>
        <Col {...valueColProps}>
          <label>{_t("offer_gross_price")}</label>
        </Col>
        <Col {...valueColProps}>
          <label>{_t("offer_net_price")}</label>
        </Col>
      </Row>
      <Row className="mb-05">
        <Col {...labelColProps}>{_t("offer_salesPrice")}</Col>
        <Col {...valueColProps}>{formatPrice(salesPriceGross)}</Col>
        <Col {...valueColProps}>{formatPrice(salesPrice)}</Col>
      </Row>
      <Row className="mb-05">
        <Col {...labelColProps}>{_t("offer_discount")}</Col>
        <Col {...valueColProps}>{formatPrice((discountGross || 0) * -1)}</Col>
        <Col {...valueColProps}>{formatPrice((discount || 0) * -1)}</Col>
      </Row>
      <Row className="mb-05">
        <Col {...labelColProps}>{_t("offer_priceAfterDiscount")}</Col>
        <Col {...valueColProps}>{formatPrice(priceAfterDiscountGross)}</Col>
        <Col {...valueColProps}>{formatPrice(priceAfterDiscount)}</Col>
      </Row>
      <Row className="mb-05">
        <Col {...labelColProps}>{_t("offer_discountFee")}</Col>
        <Col {...valueColProps}></Col>
        <Col {...valueColProps}>{formatPrice(discountFee)}</Col>
      </Row>
      <Row className="mb-05">
        <Col {...labelColProps}>{_t("offer_priceAfterDiscountHandling")}</Col>
        <Col {...valueColProps}>{formatPrice(priceAfterDiscountHandlingGross)}</Col>
        <Col {...valueColProps}>{formatPrice(priceAfterDiscountHandling)}</Col>
      </Row>
      <DividerLine className="mb-05 mt-05"/>
      <Row className="mb-05">
        <Col {...labelColProps}>{_t("offer_processingFee")}</Col>
        <Col {...valueColProps}></Col>
        <Col {...valueColProps}>{formatPrice(processingFee)}</Col>
      </Row>

      <Row className="mb-05">
        <Col {...labelColProps}>{_t("offer_transportFee")}</Col>
        <Col {...valueColProps}></Col>
        <Col {...valueColProps}>{formatPrice(transportFee)}</Col>
      </Row>

      {additionalTransportFee ? (
        <Row className="mb-05">
          <Col {...labelColProps}>{_t("offer_additionalTransportFee")}</Col>
          <Col {...valueColProps}></Col>
          <Col {...valueColProps}>{formatPrice(additionalTransportFee)}</Col>
        </Row>
      ) : null}

      <Row className="mb-05">
        <Col {...labelColProps}>{_t("offer_transportAddonPrice")}</Col>
        <Col {...valueColProps}></Col>
        <Col {...valueColProps}>{formatPrice(transportAddonPrice)}</Col>
      </Row>

      <Row className="mb-05">
        <Col {...labelColProps}>{_t("offer_transportTollFee")}</Col>
        <Col {...valueColProps}></Col>
        <Col {...valueColProps}>{formatPrice(transportTollFee)}</Col>
      </Row>

      <Row className="mb-05">
        <Col {...labelColProps}>{_t("offer_invoiceFee")}</Col>
        <Col {...valueColProps}></Col>
        <Col {...valueColProps}>{formatPrice(invoiceFee)}</Col>
      </Row>

      {additionalArrangementsInfo ? (
        <Row className="mb-05">
          <Col {...labelColProps}>{additionalArrangementsInfo}</Col>
          <Col {...valueColProps}></Col>
          <Col {...valueColProps}>{formatPrice(additionalArrangementsPrice)}</Col>
        </Row>
      ) : null}

      <Row className="mb-05 text-dark text-bold">
        <Col {...labelColProps}>{_t("offer_totalFees")}</Col>
        <Col {...valueColProps}></Col>
        <Col {...valueColProps}>{formatPrice(totalFees)}</Col>
      </Row>

      <DividerLine className="mt-05"/>
      <Row justify="space-between" className="mb-2 text-dark text-large text-bold">
        <Col>{_t("total_price")}</Col>
        <Col>{formatPrice(totalPrice)}</Col>
      </Row>
    </>
  );
};

export default ImportOfferInfoTable;
