import { Form, Typography } from "antd";
import { FC } from "react";
import appConfig from "../../../appConfig";
import EnumRadio from "../../../components/form/EnumRadio";
import EnumSelect from "../../../components/form/enumSelect";
import InputPrice from "../../../components/form/inputPrice";
import LeasingStore from "../../../contexts/leasingStore";
import _t from "../../../lang/translate";
import { getApiBaseUrl } from "../../../services/apiEndpoint";
import LeasingCarWarrantyActiveSwitch from "./leasingCarWarrantyActiveSwitch";

const LeasingCarWarranty: FC = () => {
  const { leasingCase } = LeasingStore.useContainer();
  const { inputLocked, readonly } = leasingCase.flags;
  const disabled = inputLocked || readonly;

  const shouldUpdate = (curr: any, next: any) =>
    curr?.carWarrantyInput?.active !== next?.carWarrantyInput?.active;

  return (
    <Form.Item noStyle shouldUpdate={shouldUpdate}>
      {({ getFieldsValue }) => {
        const isActive = getFieldsValue()?.carWarrantyInput?.active;
        return (
          <>
            <LeasingCarWarrantyActiveSwitch disabled={disabled} />
            {isActive && (
              <>
                <Form.Item
                  name={["carWarrantyInput", "periodId"]}
                  label={_t("period")}
                >
                  <EnumRadio
                    nameKey={["leasingCase", "carWarrantyPeriod"]}
                    disabled={disabled}
                  />
                </Form.Item>
                <Form.Item
                  name={["carWarrantyInput", "selectedPackageId"]}
                  label={_t("plan")}
                >
                  <EnumSelect
                    nameKey={["leasingCase", "carWarrantyPackage"]}
                    disabled={disabled}
                  />
                </Form.Item>
                <Form.Item
                  name={["carWarrantyInput", "price"]}
                  label={_t("total", "price")}
                  extra={
                  <>
                    <Typography.Link
                      href={getApiBaseUrl(appConfig("static")?.carGaranti)}
                      target="_blank"
                      className="pt-05 d-block"
                    >
                      {_t("see_coverage")} ({_t("pdf")})
                    </Typography.Link>

                    <Typography.Link
                      href={getApiBaseUrl(appConfig("static")?.carGarantiElectric)}
                      target="_blank"
                      className="pt-05 d-block"
                    >
                      {_t("see_coverage")} ({_t('electric')}) ({_t("pdf")})
                    </Typography.Link>
                  </>
                  }
                >
                  <InputPrice
                    min={0}
                    disabled={disabled}
                    suffix={_t("dkk_price_short")}
                  />
                </Form.Item>
              </>
            )}
          </>
        );
      }}
    </Form.Item>
  );
};

export default LeasingCarWarranty;
