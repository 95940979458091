import React, {FC} from "react";
import {Checkbox, Col, Form, Input, Row} from "antd";
import InputNum from "../../../../components/form/inputNum";
import InputPrice from "../../../../components/form/inputPrice";
import _t from "../../../../lang/translate";
import format from "../../../../utilities/formatNumbers";
import {getTotalFees, getTotalPrice} from "../calc/calculation";
import DividerLine from "../../../../components/lineSplit";
import AutoCalcSwitch from "../../../../components/form/autoCalcSwitch";

interface AutoCalcFieldProps {
  baseKey: string,
  fieldKey: string,
  label: string,
}

const AutoCalcField: FC<AutoCalcFieldProps> = ({fieldKey, label, baseKey}) => {
  const shouldUpdate = (curr: any, next: any) =>
    curr[baseKey][fieldKey] !== next[baseKey][fieldKey] ||
    (
      curr[baseKey].autoCalculate &&
      next[baseKey].autoCalculate &&
      curr[baseKey].autoCalculate[fieldKey] !== next[baseKey].autoCalculate[fieldKey]
    )

  return (
    <Form.Item shouldUpdate={shouldUpdate} noStyle>
      {({getFieldValue}) => {
        const autoCalculate = getFieldValue([baseKey, "autoCalculate", fieldKey]);

        return (
          <Row className="flex-nowrap">
            <Col flex="auto">
              <Form.Item name={[baseKey, fieldKey]} label={label}>
                <InputPrice disabled={!!autoCalculate}/>
              </Form.Item>
            </Col>
            <Col flex="49px" className="text-right mt-xl-2">
              <Form.Item noStyle name={[baseKey, "autoCalculate", fieldKey]}>
                <AutoCalcSwitch/>
              </Form.Item>
            </Col>
          </Row>
        );
      }}
    </Form.Item>
  )
}

const ImportOfferFormItems: FC = () => {
  return (
    <>
      <Form.Item label={_t("offer_can_deliver_vehicle_net_price")}>
        <Row gutter={16} align="middle">
          <Col span={12}>
            <Form.Item name={["offer", "isNetPriceVehicle"]} noStyle valuePropName="checked">
              <Checkbox>{_t("yes")}</Checkbox>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={["offer", "vatPercentage"]} noStyle>
              <InputNum suffix="%"/>
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
      <Form.Item label=" " className="m-0">
        <Row gutter={16} align="middle" className="text-center">
          <Col span={12}>
            <h4>{_t("offer_gross_price")}</h4>
          </Col>
          <Col span={12}>
            <h4>{_t("offer_net_price")}</h4>
          </Col>
        </Row>
      </Form.Item>
      <Form.Item label={_t("offer_salesPrice")}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name={["offer", "salesPriceGross"]} noStyle>
              <InputPrice/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={["offer", "salesPrice"]} noStyle>
              <InputPrice/>
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
      <Form.Item label={_t("offer_discount")}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name={["offer", "discountGross"]} noStyle>
              <InputPrice/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={["offer", "discount"]} noStyle>
              <InputPrice/>
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
      <Form.Item label={_t("offer_priceAfterDiscount")}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name={["offer", "priceAfterDiscountGross"]} noStyle>
              <InputPrice/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={["offer", "priceAfterDiscount"]} noStyle>
              <InputPrice/>
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>

      <DividerLine className="mt-05"/>

      <AutoCalcField
        baseKey={"offer"}
        fieldKey={"discountFee"}
        label={_t("offer_discountFee")}
      />
      <Form.Item label={_t("offer_priceAfterDiscountHandling")}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name={["offer", "priceAfterDiscountHandlingGross"]} noStyle>
              <InputPrice readOnly={true} className="ant-input-disabled"/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={["offer", "priceAfterDiscountHandling"]} noStyle>
              <InputPrice readOnly={true} className="ant-input-disabled"/>
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>

      <DividerLine className="mt-05"/>

      <AutoCalcField
        baseKey={"offer"}
        fieldKey={"processingFee"}
        label={_t("offer_processingFee")}
      />

      <Form.Item label={_t("offer_special_transport_arrangement")}>
        <Row gutter={16} align="middle">
          <Col span={12}>
            <Form.Item name={["offer", "isSpecialTransportArrangement"]} noStyle valuePropName="checked">
              <Checkbox>{_t("yes")}</Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>

      <AutoCalcField
        baseKey={"offer"}
        fieldKey={"transportFee"}
        label={_t("offer_transportFee")}
      />

      <Row gutter={16} align="middle" className={"mb-1"}>
        <Col flex="auto">
          <label>{_t('offer_transportAddonPrice')}</label>
        </Col>

        <Col flex="none">
          <Form.Item name={["offer", "isSuv"]} valuePropName="checked" noStyle={true}>
            <Checkbox>
              {_t('suv')}
            </Checkbox>
          </Form.Item>
          <Form.Item name={["offer", "isVan"]} valuePropName="checked" noStyle={true}>
            <Checkbox>
              {_t('van')}
            </Checkbox>
          </Form.Item>
        </Col>

        <Col flex="49px"/>
      </Row>

      <AutoCalcField
        baseKey={"offer"}
        fieldKey={"transportAddonPrice"}
        label="&nbsp;"
      />

      <AutoCalcField
        baseKey={"offer"}
        fieldKey={"transportTollFee"}
        label={_t("offer_transportTollFee")}
      />

      <AutoCalcField
        baseKey={"offer"}
        fieldKey={"invoiceFee"}
        label={_t("invoice_fee")}
      />

      <DividerLine className="mt-05" orientation="left">{_t('offer_additionalArrangements')}</DividerLine>

      <Form.Item name={["offer", "additionalArrangementsInfo"]} label={_t("description")}>
        <Input/>
      </Form.Item>

      <Form.Item name={["offer", "additionalArrangementsPrice"]} label={_t("amount")}>
        <InputPrice/>
      </Form.Item>

      <DividerLine className="mt-05"/>

      <Form.Item label={<h3 className="m-0">{_t("offer_totalFees")}</h3>} shouldUpdate={true}>
        {({getFieldValue}) => {
          const offer = getFieldValue("offer");
          const total = getTotalFees(offer);
          return <h3 className="m-0">{format.price(total, 0, "EUR")}</h3>;
        }}
      </Form.Item>

      <Form.Item label={<h3 className="m-0">{_t("offer_totalPrice")}</h3>} shouldUpdate={true}>
        {({getFieldValue}) => {
          const offer = getFieldValue("offer");
          const total = getTotalPrice(offer);
          return <h3 className="m-0">{format.price(total, 0, "EUR")}</h3>;
        }}
      </Form.Item>
    </>
  );
};

export default ImportOfferFormItems;
