import { Col, Collapse, Row } from "antd";
import {FC, useEffect, useState} from "react";
import CustomCollapse from "../../../../components/customCollapse";
import CustomCollapseHeader from "../../../../components/customCollapseHeader";
import Details from "../../../../components/detailsList";
import DividerLine from "../../../../components/lineSplit";
import LeasingStore from "../../../../contexts/leasingStore";
import _t from "../../../../lang/translate";
import format from "../../../../utilities/formatNumbers";
import LeasingDealActiveSettings from "./leasingDealActiveSettings";
import LeasingDealExpireSettings from "./leasingDealExpireSettings";
import LockCaseButton from "./lockCaseButton";
import SendLeasingCode from "./sendLeasingCode";
import moment from "moment";
import appConfig from "../../../../appConfig";

const LeasingAdminDeal: FC = () => {
  const { adminValues, leasingCase } = LeasingStore.useContainer();
  const { actualRestValue, currentLeasingMonth } = adminValues?.deal || {};
  const [expectedExpirationDate, setExpectedExpirationDate] = useState<string|null>(null);

  useEffect(() => {
    let result = null;

    if(leasingCase.deal.input.leasingPeriod && adminValues?.deal?.activeSettings.startDateDmr) {
      const date = moment(adminValues.deal.activeSettings.startDateDmr).add(leasingCase.deal.input.leasingPeriod, 'month').subtract(1, 'day');
      result = date.format(appConfig("dateFormat"));
    }

    setExpectedExpirationDate(result);
  }, [leasingCase, adminValues]);

  return (
    <>
      <CustomCollapse>
        <Collapse.Panel
          key="active_settings"
          header={<CustomCollapseHeader>{_t("active", "settings")}</CustomCollapseHeader>}
        >
          <LeasingDealActiveSettings />
        </Collapse.Panel>
        <Collapse.Panel
          key="expire_settings"
          header={<CustomCollapseHeader>{_t("expire", "settings")}</CustomCollapseHeader>}
        >
          <LeasingDealExpireSettings />
        </Collapse.Panel>
      </CustomCollapse>

      <Details borderLess>
        <Details.Item label={_t("actual_leasing_month")} value={currentLeasingMonth || "-"} />
        <Details.Item
          label={_t("actual_restValue")}
          value={actualRestValue ? `${format.price(actualRestValue)} (${_t("exclude_short", "vat")})` : "-"}
        />
        <Details.Item label={_t("expected", 'expiration_date')} value={expectedExpirationDate || "-"} />
      </Details>

      <DividerLine fluid className="mb-1" />
      <Row justify="space-between">
        <Col>
          <LockCaseButton />
        </Col>
        <Col>
          <SendLeasingCode />
        </Col>
      </Row>
    </>
  );
};

export default LeasingAdminDeal;
