import React, { FC, useCallback, useEffect, useState } from "react";
import { Button, Col, Divider, Form, message, Popconfirm, Row } from "antd";
import ImportCaseStore from "../../../../contexts/importCaseStore";
import debounce from "lodash/debounce";
import { ImportVehicleReport } from "../../../../types/importTypes";
import _t from "../../../../lang/translate";
import ImportOfferFormItems from "./importOfferFormItems";
import ImportCarDataForm from "./importCarDataForm";
import ImportServiceForm from "./importServiceForm";
import ImportCondationForm from "./importCondationForm";
import consts from "../../../../utilities/consts";
import SavedMessage from "../../../../components/messages/savedMessage";
import { updateImportReportOffer } from "../../../../services/importService";
import { compare } from "../../../../utilities/deepCompare";
import SavedStatus from "../../../../components/savedStatus";
import { updateValuesCalculation } from "../calc/calculation";
import DividerLine from "../../../../components/lineSplit";
import ErrorMessage from "../../../../components/messages/errorMessage";
import appConfig from "../../../../appConfig";
import format from "../../../../utilities/formatNumbers";

interface ImportReportFormProps {
  stopEditing: () => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const ImportReportForm: FC<ImportReportFormProps> = ({ stopEditing, setLoading }) => {
  const { importCase, setImportCase } = ImportCaseStore.useContainer();
  const { vehicleOffer } = importCase;
  const [reportForm] = Form.useForm<ImportVehicleReport>();
  const [savedStatus, setSavedStatus] = useState<boolean>(true);

  const handleSave = async (values: ImportVehicleReport) => {
    try {
      setLoading(true);
      const { data } = await updateImportReportOffer(importCase.id, values);
      setImportCase(data);
      message.success(<SavedMessage />);
      stopEditing();
    } catch ({ response }) {
      const { message: msg } = response?.data || {};
      message.error(<ErrorMessage message={msg} />);
    } finally {
      setLoading(false);
    }
  };

  const applyVATRate = (value: number) => {
    // if not net price, add vat to fees
    const isNetPriceVehicle = reportForm.getFieldValue(['offer', 'isNetPriceVehicle']);
    const vatRate = reportForm.getFieldValue(['offer', 'vatPercentage']);
    return isNetPriceVehicle ? value : value * (1 + vatRate / 100);
  }

  const calculateTransportFee = useCallback(() => {
    const prices = importCase?.vehicleOffer?.offer.prices.transportFees || [];
    const fromZip = Number(importCase?.merchant?.zip?.replace(/[^0-9.]/g, ""));
    const toZip = Number(importCase?.client?.zip);
    let val = 0;

    if (fromZip && toZip) {
      prices.forEach(set => {
        const fromStart = set?.fromStart || 0;
        const fromEnd = set?.fromEnd || 0;

        if (fromZip >= fromStart && fromZip <= fromEnd) {
          set && set.prices && set.prices.forEach(subSet => {
            const toStart = subSet?.toStart || 0;
            const toEnd = subSet?.toEnd || 0;

            if (toZip >= toStart && toZip <= toEnd) {
              const tmp = Number(subSet?.price);
              val = Math.ceil(tmp / appConfig("conversionRate"));
            }
          });
        }
      });
    }

    val = applyVATRate(val);

    reportForm.setFieldsValue({ "offer": { "transportFee": Math.ceil(val) } });
  }, [importCase, reportForm]);

  const calculateTransportAddonPrice = useCallback(() => {
    const { offer } = reportForm.getFieldsValue();
    const transportFee = offer?.transportFee || 0;
    let val = 0.0;

    if (!!offer?.isSuv) {
      const percentage = importCase.importCaseValueConfig?.addonSuvPercentage?.value || 0;
      val = val + (percentage * transportFee);
    }

    if (!!offer?.isVan) {
      const percentage = importCase.importCaseValueConfig?.addonVanPercentage?.value || 0;
      val = val + (percentage * transportFee);
    }

    reportForm.setFieldsValue({ "offer": { "transportAddonPrice": Math.ceil(val) } });
  }, [importCase, reportForm]);

  const calculateTransportTollFee = useCallback(() => {
    const { offer } = reportForm.getFieldsValue();
    let val = 5;

    if (!!offer?.isSuv) {
      val = 5;
    }

    if (!!offer?.isVan) {
      val = 9;
    }

    reportForm.setFieldsValue({ "offer": { "transportTollFee": Math.ceil(val) } });
  }, [importCase, reportForm]);

  const calculateInvoiceFee = useCallback(() => {
    let val = importCase.importCaseValueConfig?.invoiceFee?.value || 0;
    val = applyVATRate(val);
    reportForm.setFieldsValue({ "offer": { "invoiceFee": Math.ceil(val) } });
  }, [importCase, reportForm]);

  const calculateProcessingFee = useCallback(() => {
    let val = importCase.importCaseValueConfig?.handlingFee?.value || 0;
    val = applyVATRate(val);
    reportForm.setFieldsValue({ "offer": { "processingFee": Math.ceil(val) } });
  }, [importCase, reportForm]);

  const calculateDiscountFee = useCallback(() => {
    const { offer } = reportForm.getFieldsValue();
    const discountAmount = offer?.discount || 0;
    const percentage = vehicleOffer?.offer.prices.discountFeePercentage || 0;
    const val = discountAmount * percentage;
    reportForm.setFieldsValue({ "offer": { "discountFee": Math.ceil(val) } });
  }, [importCase, reportForm]);

  const calculatePriceAfterHandling = useCallback(() => {
    const { offer } = reportForm.getFieldsValue();
    const priceAfterDiscount = offer?.priceAfterDiscount || 0;
    const priceAfterDiscountGross = offer?.priceAfterDiscountGross || 0;
    const discountFee = offer?.discountFee || 0;
    const percentage = offer?.vatPercentage || 0;
    const discountFeeGross = Math.round(discountFee + ((discountFee * percentage) / 100));
    const priceAfterDiscountHandling = (priceAfterDiscount || 0) + discountFee;
    const priceAfterDiscountHandlingGross = (priceAfterDiscountGross || 0) + discountFeeGross;

    reportForm.setFieldsValue({
      "offer": {
        "priceAfterDiscountHandling": Math.ceil(priceAfterDiscountHandling),
        "priceAfterDiscountHandlingGross": Math.ceil(priceAfterDiscountHandlingGross),
      }
    });
  }, [reportForm]);

  const handleFormChange = (changedValues: any, values: ImportVehicleReport) => {
    if (typeof changedValues.offer === "object" && values.offer) {
      const changedFieldName = Object.keys(changedValues.offer)[0];
      const offer = updateValuesCalculation(changedFieldName, values.offer);
      reportForm.setFieldsValue({ offer });
    }

    if (!!changedValues.offer?.autoCalculate?.discountFee) {
      calculateDiscountFee();
      calculatePriceAfterHandling();
    } else if (!!values.offer?.autoCalculate?.discountFee && (
      changedValues.offer?.discount !== values.offer?.discount ||
      changedValues.offer?.discountGross !== values.offer?.discountGross
    )) {
      calculateDiscountFee();
      calculatePriceAfterHandling();
    } else if (!values.offer?.autoCalculate?.discountFee && (
      changedValues.offer?.discountFee !== null
    )) {
      calculatePriceAfterHandling();
    }

    if (!!changedValues.offer?.autoCalculate?.invoiceFee) {
      calculateInvoiceFee();
    } else if (!!values.offer?.autoCalculate?.invoiceFee && changedValues.offer?.invoiceFee !== values.offer?.invoiceFee) {
      calculateInvoiceFee();
    }

    if (!!changedValues.offer?.autoCalculate?.processingFee) {
      calculateProcessingFee();
    } else if (!!values.offer?.autoCalculate?.processingFee && changedValues.offer?.processingFee !== values.offer?.processingFee) {
      calculateProcessingFee();
    }

    if (!!changedValues.offer?.autoCalculate?.transportFee) {
      calculateTransportFee();
    } else if (!!values.offer?.autoCalculate?.transportFee && changedValues.offer?.transportFee !== values.offer?.transportFee) {
      calculateTransportFee();
    }

    if (!!changedValues.offer?.autoCalculate?.transportAddonPrice) {
      calculateTransportAddonPrice();
    } else if (!!values.offer?.autoCalculate?.transportAddonPrice && (
      changedValues.offer?.isSuv !== values.offer?.isSuv ||
      changedValues.offer?.isVan !== values.offer?.isSuv ||
      changedValues.offer?.transportFee !== values.offer?.transportFee
    )
    ) {
      calculateTransportAddonPrice();
    }

    if (!!changedValues.offer?.autoCalculate?.transportTollFee) {
      calculateTransportTollFee();
    } else if (!!values.offer?.autoCalculate?.transportTollFee && (
      changedValues.offer?.isSuv !== values.offer?.isSuv ||
      changedValues.offer?.isVan !== values.offer?.isSuv
    )
    ) {
      calculateTransportTollFee();
    }

    delayedSetMatched(values, importCase.vehicle);
  };

  useEffect(() => {
    !!importCase.vehicleOffer?.offer.autoCalculate?.transportFee && calculateTransportFee();
    !!importCase.vehicleOffer?.offer.autoCalculate?.transportTollFee && calculateTransportTollFee();
    !!importCase.vehicleOffer?.offer.autoCalculate?.transportAddonPrice && calculateTransportAddonPrice();
    !!importCase.vehicleOffer?.offer.autoCalculate?.processingFee && calculateProcessingFee();
    !!importCase.vehicleOffer?.offer.autoCalculate?.invoiceFee && calculateInvoiceFee();
    !!importCase.vehicleOffer?.offer.autoCalculate?.discountFee && calculateDiscountFee();
  }, [
    importCase,
    calculateTransportFee,
    calculateTransportTollFee,
    calculateTransportAddonPrice,
    calculateProcessingFee,
    calculateInvoiceFee,
    calculateDiscountFee
  ]);

  useEffect(() => {
    !!importCase.vehicleOffer?.offer.autoCalculate?.transportFee && calculateTransportFee();
    !!importCase.vehicleOffer?.offer.autoCalculate?.transportAddonPrice && calculateTransportAddonPrice();
  }, [
    importCase.vehicleOffer,
    calculateTransportFee,
    calculateTransportAddonPrice,
    importCase.merchant?.zip
  ]);

  const delayedSetMatched = useCallback(
    debounce((values: ImportVehicleReport, savedValues: any) => {
      const isMatched = compare(values, savedValues);
      if (isMatched !== savedStatus) setSavedStatus(isMatched);
    }, 500),
    [setSavedStatus, savedStatus]
  );

  return (
    <Form
      {...consts.formItemProps}
      form={reportForm}
      className="m-0"
      requiredMark={false}
      onValuesChange={handleFormChange}
      onFinish={handleSave}
      validateTrigger="onChange"
      initialValues={vehicleOffer || {}}
    >
      <Divider orientation="left">{_t("import_offer_form_title")}</Divider>
      <ImportOfferFormItems />
      <Divider orientation="left">{_t("import_car_data_form_title")}</Divider>
      <ImportCarDataForm />
      <Divider orientation="left">{_t("service_en")}</Divider>
      <ImportServiceForm />
      <Divider orientation="left">{_t("car_condition")}</Divider>
      <ImportCondationForm />
      <DividerLine weight={1.5} fluid />
      <Form.Item noStyle shouldUpdate>
        {({ submit, getFieldValue }) => (
          <Row justify="space-between">
            <Col>
              <Button onClick={stopEditing}>{_t("cancel")}</Button>
            </Col>
            <Col>
              <SavedStatus status={savedStatus} />
              {!getFieldValue(["offer", "transportFee"]) || getFieldValue(["offer", "transportFee"]) < 1 ? (
                <Popconfirm onConfirm={submit} title={`OBS: Transport er ${format.price(getFieldValue(["offer", "transportFee"]), 0, "EUR")}`} >
                  <Button type="primary">
                    {_t("save")}
                  </Button>
                </Popconfirm>
              ) : (
                <Button type="primary" onClick={submit}>
                  {_t("save")}
                </Button>
              )}
            </Col>
          </Row>
        )}
      </Form.Item>
    </Form>
  );
};

export default ImportReportForm;
