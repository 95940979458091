import { Col, Popover, Row, Space } from "antd";
import { FC } from "react";
import CardBox from "../../../components/cardBox";
import StatusTag from "../../../components/statusTag";
import _t from "../../../lang/translate";
import { DealerAdminProductType } from "../../../types/dealerTypes";
import format from "../../../utilities/formatNumbers";
import DealerUpdateLevelButton from "./dealerUpdateLevelButton";

interface DealerAdminLevelProps {
  product: DealerAdminProductType;
}

const DealerAdminLevel: FC<DealerAdminLevelProps> = ({ product }) => {
  const { cvrRate, level, note, note2, forced } = product.level;

  return (
    <CardBox bordered className="no-bg">
      <Row justify="space-between" align="middle">
        <Col>
          <Space>
            <StatusTag size="small" color="processing" locked={forced}>
              {level}
            </StatusTag>
            <StatusTag size="small" color="success">
              {format.percent(cvrRate)}
            </StatusTag>
            {note && (
              <Popover title={_t("notice")} content={note}>
                <StatusTag size="small" color="gold">
                  {_t("note")}
                </StatusTag>
              </Popover>
            )}
            {note2 && (
              <Popover title={_t("notice")} content={note2}>
                <StatusTag size="small" color="gold">
                  {_t("note")} 2
                </StatusTag>
              </Popover>
            )}
          </Space>
        </Col>
        <Col>
          <DealerUpdateLevelButton product={product} />
        </Col>
      </Row>
    </CardBox>
  );
};

export default DealerAdminLevel;
